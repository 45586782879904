<template>
  <div class="h-full w-full p-8 flex flex-col">
    <SearchTemplate
      :formData="formData"
      labelWidth="80px"
      :placeholderText="placeholder"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    >
      <template slot="more">
        <el-form-item label="申请日期">
          <el-date-picker
            v-model="joinTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="dateChangeFn"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所在地区">
          <SelectAreaMul @change="areaChangeFn" :areaData="formData.areaArr" />
        </el-form-item>
        <el-form-item label="申请人类型">
          <el-select v-model="formData.type" clearable placeholder="申请人类型">
            <el-option
              v-for="item in typeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </SearchTemplate>
    <el-table
      :data="tableData"
      border
      class="table-base m-t-16"
      size="small"
      style="width: 100%"
      height="400px"
    >
      <el-table-column prop="cooperatorName" label="申请人名称" min-width="180">
        <template slot-scope="scope">
          <div class="flex justify-around px-8">
            <div class="unit_name">
              {{ renderName(scope.row) }}
            </div>
            <i
              class="iconfont icon-line_message1 cursor-pointer"
              @click="sendMsg(scope.row)"
            ></i>
          </div>
          <div v-if="!scope.row.swCooperatorId">
            <span class="not_associat">未关联本地往来单位</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="cooperatorPhone"
        align="center"
        label="联系电话"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.cooperatorPhone || scope.row.creatorPhone }}
        </template>
      </el-table-column>
      <el-table-column
        prop="cooperatorAreaName"
        show-overflow-tooltip
        align="center"
        label="所在地区"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="cooperatorTypeName"
        align="center"
        label="申请人类型"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ renderUserType(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="joinTimeStr"
        align="center"
        label="申请时间"
        min-width="120"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="审核">
        <template slot-scope="scope">
          <div v-if="scope.row.status === 0">
            <el-button type="text" size="mini" @click="agreeFn(scope.row)">
              通过
            </el-button>
            <el-button type="text" size="mini" @click="refuseFn(scope.row)">
              拒绝
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="m-t-16 text-right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageInfo.pageSize"
      layout="total,  prev, pager, next, jumper,sizes"
      :total="totalSize"
    >
    </el-pagination>
    <AuditUnitDialog
      ref="audit"
      :rowData="rowData"
      pageType="auditList"
      dialogTitle="审核-通过"
      @refresh="refreshFn"
    />
    <el-dialog title="审核-拒绝" :visible.sync="refuseVisible" width="30%">
      <div>您确定要拒绝【{{ refuseName(rowData) }}】成为您的往来单位吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuseVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureRefuseFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SearchTemplate from "@/components/search/SearchTemplate.vue";
import SelectAreaMul from "@/components/selectArea/SelectAreaMul.vue";
import AuditUnitDialog from "../components/auditUnitDialog.vue";
import { getCooperatorAuditList, postCooperatorRefuse } from "@/api/cooperator";

const defaultFormData = {
  // page: 0, //# 页码
  // pageSize: 0, //# 页宽
  joinStartTime: "", // # 加入时间起始
  joinEndTime: "", //# 加入时间截止
  keyword: "", //# 关键字
  areaArr: [],
  type: "", //# 类型:0=个人,1=公司
};

export default {
  components: {
    SearchTemplate,
    SelectAreaMul,
    AuditUnitDialog,
  },
  data() {
    return {
      placeholder: "申请人姓名/电话等关键字",
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      joinTime: [],
      typeOptions: [
        { name: "个人", code: "person" },
        { name: "公司-经销商", code: "dealer" },
        { name: "公司-修理厂", code: "garage" },
        { name: "公司-生产厂家", code: "manufacturer" },
      ],
      pageInfo: {
        page: 1,
        pageSize: 20,
      },
      totalSize: 0,
      tableData: [
        // {
        //   id: 0, // # 自增ID
        //   companyId: 0, //# 公司ID
        //   cooperatorId: 0, // # 往来单位ID（用户id或公司id）
        //   cooperatorType: 0, // # 类型 0:个人，1:公司
        //   Flags: 0, //# 标记位: 第一位:是否是客户;第二位:是否是供应商;第三位:是否是物流公司;第四位:是否是保险公司;
        //   status: 0, //# 客户状态 0:待审核 1:正常 2:停用
        //   swCooperatorId: 0, // # 软件内往来单位ID
        //   joinTime: "2022-06-15 18:02:28", // # 加入时间/申请时间(待审核)
        //   createdBy: 0, //# 创建者员工ID
        //   updatedBy: 0, // # 更新者员工ID
        //   updatedAt: "0001-01-01T00:00:00Z", //	 # 更新时间
        //   cooperatorName: "单位名称", //# 单位名称
        //   cooperatorPhone: "13211111111", //# 电话
        //   cooperator_area_id: 0, // # 区域ID
        //   isCustomer: false, //# 是否是客户
        //   isSupplier: false, // # 是否是供应商
        //   isLogistics: false, // # 是否是物流公司
        //   isInsuranceCompany: false, // # 是否是保险公司
        //   swCooperatorName: "往来单位名称", //# 软件内往来单位名称
        //   cooperatorTypeName: "个人", //# 类型名称:个人/公司
        //   statusName: "", //# 状态名称:正常/停用
        // },
      ],
      rowData: {},
      refuseVisible: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    refuseName(item) {
      let str = "";
      if (item.cooperatorType === 0) {
        str += item.creatorName;
      } else {
        str +=
          item.cooperatorName +
          (item.creatorName ? `-${item.creatorName}` : "");
      }
      return str;
    },
    renderName(item) {
      let str = "";
      if (item.cooperatorType === 0) {
        str +=
          item.creatorName +
          (item.swCooperatorName ? `（${item.swCooperatorName}）` : "");
      } else {
        str +=
          item.cooperatorName +
          (item.creatorName ? `-${item.creatorName}` : "") +
          (item.swCooperatorName ? `（${item.swCooperatorName}）` : "");
      }
      return str;
    },
    renderUserType(item) {
      let str = "";
      if (item.cooperatorType) {
        if (item.companyType == "dealer") {
          str = "公司-经销商";
        } else if (item.companyType == "garage") {
          str = "公司-修理厂";
        } else if (item.companyType == "manufacturer") {
          str = "公司-生产厂家";
        }
      } else {
        str = "个人";
      }
      return str;
    },
    sendMsg(e) {
      this.$store.dispatch("setChatId", e.creatorId);
      this.$store.dispatch("changeIMVisible", true);
    },
    refreshFn() {
      this.pageInfo.page = 1;
      this.initData();
    },
    initData() {
      let params = {
        ...this.formData,
        ...this.pageInfo,
      };
      getCooperatorAuditList(params).then((res) => {
        this.tableData = res?.rows || [];
        this.totalSize = res?.totalSize;
      });
    },
    handleQuery() {
      this.pageInfo.page = 1;
      this.initData();
    },
    handleReset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
      this.joinTime = [];
    },
    areaChangeFn(data) {
      this.formData.areaArr = data;
    },
    dateChangeFn(date) {
      if (date) {
        this.formData.joinStartTime = date[0];
        this.formData.joinEndTime = date[1];
      } else {
        this.formData.joinStartTime = "";
        this.formData.joinEndTime = "";
      }
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    agreeFn(row) {
      this.rowData = row;
      this.$refs.audit.show();
    },
    refuseFn(row) {
      this.rowData = row;
      this.refuseVisible = true;
    },
    sureRefuseFn() {
      let params = {
        id: this.rowData.id,
      };
      postCooperatorRefuse(params).then(() => {
        this.$message.success("拒绝成功");
        this.refuseVisible = false;
        this.initData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.unit_name {
  color: #6589f8;
}
.not_associat {
  display: inline-block;
  margin-top: 4px;
  color: #666666;
  padding: 0px 4px;
  border: 1px solid #999999;
  border-radius: 4px;
  background: #f5f5f5;
}
</style>
